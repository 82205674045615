const memberRouter = {
  route: null,
  name: null,
  title: '折扣券',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-shangpinguanli',
  filePath: 'view/trailer/', // 文件路径
  order: 4,
  inNav: true,
  children: [
    {
      title: '优惠券管理',
      type: 'view',
      name: 'TrailerList',
      route: '/trailer/list',
      filePath: 'view/trailer/trailer.vue',
      inNav: true,
      icon: 'iconfont icon-jiemiansheji'
    },
    {
      title: '抵扣券管理',
      type: 'view',
      name: 'DeductionList',
      route: '/deduction/list',
      filePath: 'view/trailer/deduction.vue',
      inNav: true,
      icon: 'iconfont icon-jiemiansheji'
    }
  ],
}

export default memberRouter
