const merchantRouter = {
  route: null,
  name: null,
  title: '车友圈',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-huiyuanguanli',
  filePath: 'view/merchant/', // 文件路径
  order: 3,
  inNav: true,
  children: [
    {
      title: '商户管理',
      type: 'view',
      name: 'merchantList',
      route: '/merchant/list',
      filePath: 'view/share/share-list.vue',
      inNav: true,
      icon: 'iconfont icon-shangpinguanli',
    },
    {
      title: '分类管理',
      type: 'view',
      name: 'tagList',
      route: '/merchant/tags',
      filePath: 'view/merchantTags/tag-list.vue',
      inNav: true,
      icon: 'iconfont icon-shangpinguanli',
    },
    {
      title: '添加商户',
      type: 'view',
      name: 'merchantAdd',
      route: '/merchant/add',
      filePath: 'view/share/share-add.vue',
      inNav: true,
      icon: 'iconfont icon-tianjia',
    },
    {
      title: '编辑商户',
      type: 'view',
      name: 'merchantEdit',
      route: '/merchant/edit',
      filePath: 'view/share/share-edit.vue',
      inNav: false,
      icon: 'iconfont icon-fuwenbenbianjiqi_gongshi',
    },
  ],
}

export default merchantRouter
