const productRouter = {
  route: null,
  name: null,
  title: '订单管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-huiyuanguanli',
  filePath: 'view/order/', // 文件路径
  order: 5,
  inNav: true,
  children: [
    {
      title: '订单列表',
      type: 'view',
      name: 'product',
      route: '/order/list',
      filePath: 'view/order/list.vue',
      inNav: true,
      icon: 'iconfont icon-shangpinguanli'
    }
  ],
}

export default productRouter
