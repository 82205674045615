const memberRouter = {
  route: null,
  name: null,
  title: '试驾车管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-fenxiang',
  filePath: 'view/car/', // 文件路径
  order: 3,
  inNav: true,
  children: [
    {
      title: '试驾车列表',
      type: 'view',
      name: 'carList',
      route: '/car/list',
      filePath: 'view/car/car-list.vue',
      inNav: true,
      icon: 'iconfont icon-jiemiansheji',
    },
    {
      title: '添加试驾车',
      type: 'view',
      name: 'carAdd',
      route: '/car/add',
      filePath: 'view/car/car-add.vue',
      inNav: true,
      icon: 'iconfont icon-tianjia',
    }, {
      title: '编辑试驾车',
      type: 'view',
      name: 'carEdit',
      route: '/car/edit',
      filePath: 'view/car/car-edit.vue',
      inNav: false,
      icon: 'iconfont icon-tianjia',
    }, {
      title: '汽车配置',
      type: 'view',
      name: 'carConfig',
      route: '/car/config',
      filePath: 'view/car/car-config.vue',
      inNav: true,
      icon: 'iconfont icon-tianjia',
    },
  ],
}

export default memberRouter
