const bookRouter = {
  route: null,
  name: null,
  title: '消息管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  isElementIcon: false,
  filePath: 'view/notifications/', // 文件路径
  order: 6,
  inNav: true,
  children: [
    {
      title: '消息列表',
      type: 'view',
      name: 'notificationsList',
      route: '/notifications/list',
      filePath: 'view/notifications/list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      isElementIcon: false,
    }
  ],
}

export default bookRouter
